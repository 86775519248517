import { Skeleton, useBoolean, IconButton, Text } from '@chakra-ui/react';
import { getAppSource } from '@/utils/analytics-utils';

import useUpdatePatientParams from '@/hooks/react-query/useUpdatePatientParams';
import BoxedSandModal from '../Modals/BoxedSandModal';
import { Icon } from '../Icon';
import { Card } from '../Cards/components/Card';
import { webApiClient } from '@/api-client/apiClient';
import { CustomerIOEvents } from '@innerwell/dtos';
import usePatientParams from '@/hooks/react-query/usePatientParams';
import { handleSentryException } from '@/utils/sentry';
import useThemedToast from '@/hooks/useThemedToast';
import { getErrorMessage } from '@innerwell/utils';

export const SwitchToGenPsychStep = () => {
   const { toastError } = useThemedToast();
   const [isSwitchModalOpen, { on: openSwitchModal, off: closeSwitchModal }] =
      useBoolean(false);

   const { data, isLoading } = usePatientParams();
   const { mutate: updatePatientParams } = useUpdatePatientParams();

   const isAuditedForGenPsych = data?.isAuditedForGenPsych;

   if (isLoading) {
      return <Skeleton h="14rem" rounded="lg" />;
   }

   return (
      <>
         <BoxedSandModal
            isOpen={isSwitchModalOpen}
            onClose={closeSwitchModal}
            closeOnEsc
            closeOnOverlayClick
         >
            <IconButton
               aria-label="Close"
               variant="unstyled"
               minW="0"
               icon={<Icon name="close" boxSize={5} />}
               onClick={closeSwitchModal}
               pos="absolute"
               top={0}
               right={0}
               mx={4}
            />
            <BoxedSandModal.Heading mb={7}>
               Thank you for considering recommended plan
            </BoxedSandModal.Heading>
            <Text>
               Our support team will contact you as soon as possible with the
               next steps.
            </Text>
         </BoxedSandModal>

         {isAuditedForGenPsych ? (
            <Card>
               <Card.Title>Taking a Step Back to Move Forward</Card.Title>
               <Card.Text>
                  We will review your request and get back to you as soon as
                  possible.
               </Card.Text>

               <Card.ButtonGroup>
                  <Card.ButtonLink href="/support">
                     Contact Support
                  </Card.ButtonLink>
               </Card.ButtonGroup>
            </Card>
         ) : (
            <Card>
               <Card.Title>Taking a Step Back to Move Forward</Card.Title>
               <Card.Text>
                  {`We understand you're eager to explore Ketamine
               treatment. However, based on our assessment,
               increased alcohol use may affect its safety and
               effectiveness for you right now. Let's work together!
               Our Psychiatry team can help you create a
               personalized plan to address your alcohol use and
               potentially pave the way for Ketamine treatment in
               the future. Schedule a consultation today to discuss
               your options.`}
               </Card.Text>
               <Card.ButtonGroup>
                  <Card.Button
                     onClick={() => {
                        openSwitchModal();

                        webApiClient.customerio.track({
                           body: {
                              name: CustomerIOEvents.AuditSwitchGenPsych,
                              app_source: getAppSource(),
                           },
                        });

                        updatePatientParams(
                           {
                              isAuditedForGenPsych: true,
                           },
                           {
                              onError: (error) => {
                                 handleSentryException(error);
                                 const errorMessage = getErrorMessage(error);

                                 toastError(errorMessage);
                              },
                           },
                        );
                     }}
                  >
                     Switch to recommended plan
                  </Card.Button>
               </Card.ButtonGroup>
            </Card>
         )}
      </>
   );
};
