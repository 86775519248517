import { Flex, Grid, Skeleton, Text } from '@chakra-ui/react';
import {
   InsuranceInfoStatus,
   IntakeProgramPhases,
   MyPatientResponseDto,
   TherapyPlan,
   type WelkinProgramPhases,
} from '@innerwell/dtos';
import { isIntakeProgramAfterOrEqual } from '@innerwell/utils';
import { useAppointmentReservation } from '@innerwell/ui';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';
import HomeStepUpcomingProgress from '@/components/HomeSteps/HomeStepUpcomingProgress';

import { usePatientProgram } from '@/contexts/patient-program-context';

import { PatientLocked } from '../Cards/PatientLocked/PatientLocked';
import { useUserAccess } from '../Cards/PatientLocked/useUserAccess';
import HomeCheckInsuranceEligibilityStep from '../HomeSteps/HomeCheckInsuranceEligibilityStep';
import { HomeConfirmContactConsent } from '../HomeSteps/HomeConfirmContactConsent';
import MedicalConsultStep from '../HomeSteps/Intake/Onboarding/MedicalConsultStep';
import { PatientDischargedCard } from '../Cards/PatientDischargedCard';
import usePatientLastCarePlanChoice from '@/hooks/react-query/usePatientLastCarePlanChoice';
import { InsuranceStatusCard } from '../Cards/InsuranceStatusCard';
import { usePatientHasFinishedConsent } from '@/services/patient/hooks/use-patient-has-finished-consent';
import { getStaggerVariants } from '@/utils/animation-utils';
import { motion } from 'framer-motion';
import { DividerWithText } from '../Dividers/DividerWithText';
import { HomeOnboardingStep } from '../HomeSteps/Intake/Onboarding/HomeOnboardingStep';
import { SimpleMoodTracking } from '../MoodTracking/SimpleMoodTracking';
import { ConfirmAppointmentReservationCard } from '../Cards/ConfirmAppointmentReservationCard';
import { HorizontalCard } from '../HorizontalCard/HorizontalCard';
import { RecommendationCard } from '../Cards/RecommendationCard';
import { afterConsultScheduleRecommendationLinks } from '@/utils/recommendation-links';
import { useMyProgramGoals } from '@/hooks/react-query/useMyProgramGoals';
import { MedicalConsultOnlyStep } from '../HomeSteps/MedicalConsultOnlyStep';
import { useTodayMoodTracking } from '@/hooks/react-query/useTodayMoodTracking';
import { SwitchToGenPsychStep } from '../HomeSteps/SwitchToGenPsychStep';

enum IntakeSteps {
   Onboarding,
   CheckEligibility,
   MedicalConsult,
   SetTreatmentPlan,
}

const getInsuranceCheckRequired = (
   insuranceStatus: InsuranceInfoStatus | null,
) => {
   if (!insuranceStatus) {
      return false;
   }

   return insuranceStatus !== InsuranceInfoStatus.Eligible;
};

const getStep = (
   phase: WelkinProgramPhases | null,
   insuranceStatus: InsuranceInfoStatus | null,
) => {
   if (
      phase === IntakeProgramPhases.InformedConsentFormFinished ||
      phase === IntakeProgramPhases.DailyMoodTrackingFinished ||
      phase === IntakeProgramPhases.SetProgramGoalsFinished
   ) {
      // ask for insurance if necessary only (patient bought insurance and they don't have valid insurance info CDT)
      if (getInsuranceCheckRequired(insuranceStatus)) {
         return IntakeSteps.CheckEligibility;
      }
      return IntakeSteps.MedicalConsult;
   }

   // We're not sure until we have params whether to push into MedicalConsult or not
   if (
      isIntakeProgramAfterOrEqual(
         phase,
         IntakeProgramPhases.SetProgramGoalsFinished,
      )
   ) {
      return IntakeSteps.MedicalConsult;
   }

   return IntakeSteps.Onboarding;
};

const staggerVariants = getStaggerVariants({
   staggerChildren: 0.15,
});

export const IntakeLayout = ({
   patient,
   initialPhase,
}: {
   patient: MyPatientResponseDto;
   initialPhase?: WelkinProgramPhases;
}) => {
   const {
      programPhase: { phase: _phase },
   } = usePatientProgram();

   const phase = _phase ?? initialPhase;

   const { chosenCarePlan } = usePatientLastCarePlanChoice();
   const patientHasFinishedConsent = usePatientHasFinishedConsent();

   const { push } = useRouter();

   const [currentStep, setCurrentStep] = useState<IntakeSteps | null>(null);

   const insuranceStatus = patient.insurance?.status ?? null;

   // If phase changes because of invalidated query
   useEffect(() => {
      if (!phase) {
         return;
      }

      setCurrentStep(getStep(phase, insuranceStatus));
   }, [phase, insuranceStatus]);

   useEffect(() => {
      if (phase === IntakeProgramPhases.MedicalConsultReject) {
         push('/rejected/medical-consult');
      }
      if (phase === IntakeProgramPhases.MedicalIntakeRejected) {
         push('/rejected/intake');
      }
   }, [push, phase]);

   const { isLocked, statuses } = useUserAccess();

   const handleOnboardingCompleted = () => {
      if (patientHasFinishedConsent === true) {
         setCurrentStep(IntakeSteps.MedicalConsult);
         return;
      }

      if (getInsuranceCheckRequired(insuranceStatus)) {
         setCurrentStep(IntakeSteps.CheckEligibility);
         return;
      }

      setCurrentStep(IntakeSteps.MedicalConsult);
   };

   const { appointments: upcomingAppointments } = useUpcomingAppointments();

   const { get: appointmentReservation } = useAppointmentReservation();
   const [
      isAppointmentReservationCardVisible,
      setIsAppointmentReservationCardVisible,
   ] = useState(true);

   const scheduleMeetTitle =
      chosenCarePlan === TherapyPlan.EMDR
         ? 'Schedule your EMDR Intake'
         : patient.insurance?.planType === 'psychotherapist'
           ? 'Meet your Therapist'
           : upcomingAppointments?.length > 0
             ? 'Medical Consult'
             : 'Schedule your Medical Consult';

   const { programGoals, isLoading: isLoadingProgramGoals } =
      useMyProgramGoals();
   const { data: moodTrackingData } = useTodayMoodTracking();

   const showProgramGoalsCard = !isLoadingProgramGoals && !programGoals?.length;

   // Gen switch
   const switchToGenPsych =
      patient.medicalIntakeStatus === 'switch-to-gen-psych';

   return (
      <>
         <Flex flexDir="column" gap={{ base: 4, lg: 8 }}>
            <HomeConfirmContactConsent />

            {Boolean(patient.isPatientDischarged) && <PatientDischargedCard />}

            <InsuranceStatusCard
               patient={patient}
               onCompletedEligibilityCheck={(isEligible) => {
                  if (isEligible) {
                     setCurrentStep(IntakeSteps.MedicalConsult);
                  }
               }}
               hidden={
                  patient.currentProgram?.currentPhase.name !==
                  IntakeProgramPhases.MedicalConsultOnly
               }
            />
         </Flex>

         {isLocked ? (
            <PatientLocked statuses={statuses} />
         ) : patient.currentProgram?.currentPhase.name ===
           IntakeProgramPhases.MedicalConsultOnly ? (
            <MedicalConsultOnlyStep />
         ) : (
            <Skeleton
               as={Flex}
               isLoaded={currentStep !== null}
               flexDir="column"
               gap={6}
               pb={8}
               rounded="lg"
            >
               {(currentStep === IntakeSteps.MedicalConsult &&
                  upcomingAppointments.length > 0) ||
               switchToGenPsych ? (
                  <Flex flexDir="column" gap={3}>
                     <Grid
                        gridTemplateColumns={{
                           base: '1fr',
                           lg: showProgramGoalsCard ? '1fr 1fr' : '1fr',
                        }}
                        gap={{ base: 1.5, lg: 3 }}
                     >
                        <SimpleMoodTracking
                           didTrackToday={moodTrackingData?.didTrackToday}
                           flex={1}
                           minH="6rem"
                           justifyContent="center"
                        />
                        {showProgramGoalsCard && (
                           <HorizontalCard title="Set Program Goals" flex={1}>
                              <Text pr={6}>
                                 Take a moment to track your program goals
                              </Text>

                              <HorizontalCard.Button
                                 aria-label="Track your mood"
                                 iconName="arrow-right"
                                 isLoading={false}
                                 isDisabled={false}
                                 onClick={() => {
                                    push('/set-program-goals');
                                 }}
                              />
                           </HorizontalCard>
                        )}
                     </Grid>
                     <Flex
                        gap={3}
                        overflowX="auto"
                        sx={{
                           '&::-webkit-scrollbar': {
                              display: 'none',
                           },
                        }}
                     >
                        {afterConsultScheduleRecommendationLinks.map(
                           ({ title, link, imageSrc, tag }) => (
                              <RecommendationCard
                                 key={title}
                                 href={link}
                                 isExternal={link.startsWith('http')}
                                 imageSrc={imageSrc}
                                 tag={tag}
                                 size="small"
                                 flex={1}
                                 parentFlexProps={{ w: '100%' }}
                              >
                                 {title}
                              </RecommendationCard>
                           ),
                        )}
                     </Flex>
                  </Flex>
               ) : null}

               <motion.div
                  variants={staggerVariants.container}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
               >
                  {currentStep && switchToGenPsych ? (
                     <SwitchToGenPsychStep />
                  ) : (
                     <Flex flexDir="column" gap={6}>
                        {isAppointmentReservationCardVisible &&
                        appointmentReservation &&
                        currentStep !== IntakeSteps.MedicalConsult ? (
                           <motion.div variants={staggerVariants.child}>
                              <ConfirmAppointmentReservationCard
                                 reservation={appointmentReservation}
                                 onSuccess={() => {
                                    setIsAppointmentReservationCardVisible(
                                       false,
                                    );
                                 }}
                              />
                           </motion.div>
                        ) : null}

                        {currentStep === IntakeSteps.Onboarding ? (
                           <>
                              <HomeOnboardingStep
                                 onCompleted={handleOnboardingCompleted}
                              />

                              {patient.insurance ? (
                                 <HomeStepUpcomingProgress
                                    title="Check your insurance eligibility"
                                    variant="light"
                                    fill="background.primary"
                                 />
                              ) : null}
                           </>
                        ) : null}

                        {currentStep === IntakeSteps.CheckEligibility ? (
                           <HomeCheckInsuranceEligibilityStep
                              patient={patient}
                              onCompleted={(isEligible) => {
                                 if (isEligible) {
                                    setCurrentStep(IntakeSteps.MedicalConsult);
                                 }
                              }}
                           />
                        ) : null}

                        {currentStep === IntakeSteps.MedicalConsult ? (
                           <>
                              {upcomingAppointments?.length ? (
                                 <DividerWithText text="Your upcoming appointments" />
                              ) : null}

                              {appointmentReservation &&
                              isAppointmentReservationCardVisible ? (
                                 <ConfirmAppointmentReservationCard
                                    reservation={appointmentReservation}
                                    showScheduleButton
                                    onSuccess={() => {
                                       setIsAppointmentReservationCardVisible(
                                          false,
                                       );
                                    }}
                                 />
                              ) : (
                                 <MedicalConsultStep />
                              )}
                           </>
                        ) : null}

                        {currentStep !== IntakeSteps.MedicalConsult ? (
                           <HomeStepUpcomingProgress
                              title={scheduleMeetTitle}
                              variant="light"
                              fill="background.primary"
                           />
                        ) : null}

                        <motion.div variants={staggerVariants.child}>
                           <HomeStepUpcomingProgress
                              title="Start your treatment plan"
                              variant="light"
                              fill="background.primary"
                           />
                        </motion.div>
                     </Flex>
                  )}
               </motion.div>
            </Skeleton>
         )}
      </>
   );
};
