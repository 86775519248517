import { useDisclosure } from '@chakra-ui/react';
import { getErrorMessage } from '@innerwell/utils';
import { useRouter } from 'next/navigation';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';

import { EligibilityFormIntake } from '../Insurance/EligibilityFormIntake';
import BoxedModal from '../Modals/BoxedModal';
import { Card } from '../Cards/components/Card';
import { MyPatientResponseDto } from '@innerwell/dtos';
import { useInsuranceStatusText } from '../Insurance/use-insurance-status-text';
import { INNERWELL_SUPPORT_EMAIL } from '@/utils/consts';

const HomeCheckInsuranceEligibilityStep = ({
   patient,
   onCompleted,
}: {
   patient: MyPatientResponseDto;
   onCompleted?: (isEligible: boolean | null) => void;
}) => {
   const eligibilityModal = useDisclosure();

   const { push } = useRouter();
   const { toastError } = useThemedToast();

   const handleSwitch = async () => {
      try {
         const response = await webApiClient.programSwitch.getSwitchData();

         const info = response.body;
         if (!info.therapyPlan) {
            toastError('No selected therapy plan found');
            return;
         }

         push(
            `/purchase/plan/${info.switchCategoryId}?${new URLSearchParams({
               therapyPlan: info.therapyPlan,
               ref: '/',
            }).toString()}`,
         );
      } catch (e) {
         toastError(
            `Failed to get insurance program switch info: ${getErrorMessage(e)}`,
         );
      }
   };

   const insuranceStatusText = useInsuranceStatusText(patient);

   if (!insuranceStatusText || !patient.insurance) {
      return null;
   }

   const {
      heading,
      text,
      showCheckEligibilityButton,
      showSendEmailButton,
      showContactSupportButton,
   } = insuranceStatusText;

   return (
      <>
         <Card minH="none">
            <Card.Title>{heading}</Card.Title>
            <Card.Text pr={0}>{text}</Card.Text>

            <Card.ButtonGroup>
               {showCheckEligibilityButton && (
                  <Card.Button onClick={eligibilityModal.onOpen}>
                     Check Eligibility
                  </Card.Button>
               )}

               {showSendEmailButton && (
                  <Card.ButtonLink href={`mailto:${INNERWELL_SUPPORT_EMAIL}`}>
                     Send email
                  </Card.ButtonLink>
               )}

               {showContactSupportButton && (
                  <Card.ButtonLink href="/support">
                     Contact Member Support
                  </Card.ButtonLink>
               )}

               {patient.medicalIntakeStatus === 'rejected' &&
               !showContactSupportButton ? (
                  <Card.ButtonLink href="/support" variant="link">
                     Contact Member Support
                  </Card.ButtonLink>
               ) : (
                  <Card.Button variant="link" onClick={handleSwitch}>
                     Continue Without Insurance
                  </Card.Button>
               )}
            </Card.ButtonGroup>
         </Card>
         {eligibilityModal.isOpen ? (
            <BoxedModal
               title="Insurance Eligibility"
               bg="background.secondary"
               {...eligibilityModal}
            >
               <EligibilityFormIntake
                  patient={patient}
                  onCompleted={(isEligible) => {
                     eligibilityModal.onClose();

                     onCompleted?.(isEligible);
                  }}
               />
            </BoxedModal>
         ) : null}
      </>
   );
};

export default HomeCheckInsuranceEligibilityStep;
