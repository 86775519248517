import { webApiClient } from '@/api-client/apiClient';
import { queryClient } from '@/services/react-query/query-client';
import { queryKeys } from '@/types/query-keys';
import {
   PatientParamsResponseDto,
   UpdatePatientParamsDto,
} from '@innerwell/dtos';
import { useMutation } from '@tanstack/react-query';

export default function useUpdatePatientParams() {
   return useMutation({
      mutationFn: async (params: UpdatePatientParamsDto) => {
         const response = await webApiClient.patients.updateParams({
            body: params,
         });
         return response.body;
      },
      onMutate: async (params) => {
         await queryClient.cancelQueries({
            queryKey: queryKeys.params,
         });

         const previousParams =
            queryClient.getQueryData<PatientParamsResponseDto>(
               queryKeys.params,
            );

         queryClient.setQueryData(queryKeys.params, (old) => {
            if (!old) {
               return;
            }

            return {
               ...old,
               ...params,
            };
         });

         return { previousParams };
      },
      onError: (_error, _params, context) => {
         queryClient.setQueryData(queryKeys.params, context?.previousParams);
      },
      onSettled: () => {
         queryClient.invalidateQueries({
            queryKey: queryKeys.params,
         });
      },
   });
}
