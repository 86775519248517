import { Card } from './components/Card';
import { MyPatientResponseDto } from '@innerwell/dtos';
import { usePatientProgram } from '@/contexts/patient-program-context';
import BoxedModal from '../Modals/BoxedModal';
import { Flex, useBoolean } from '@chakra-ui/react';
import { EligibilityFormIntake } from '../Insurance/EligibilityFormIntake';
import { FlexProps } from '@chakra-ui/layout';
import { useInsuranceStatusText } from '../Insurance/use-insurance-status-text';
import { INNERWELL_SUPPORT_EMAIL } from '@/utils/consts';

export const InsuranceStatusCard = ({
   patient,
   onCompletedEligibilityCheck,
   ...rest
}: {
   patient: MyPatientResponseDto;
   onCompletedEligibilityCheck?: (isEligible: boolean | null) => void;
} & FlexProps) => {
   const {
      programPhase: { phase },
   } = usePatientProgram();

   const [
      isEligibilityModalOpen,
      { on: openEligibilityModal, off: closeEligibilityModal },
   ] = useBoolean();

   const insuranceStatusText = useInsuranceStatusText(patient);

   if (!insuranceStatusText || !phase) {
      return null;
   }

   const {
      heading,
      text,
      showCheckEligibilityButton,
      showSendEmailButton,
      showContactSupportButton,
   } = insuranceStatusText;

   return (
      <Flex flexDir="column" gap={4} {...rest}>
         <Card minH="none" bg="background.secondary" maxW="none">
            <Card.Title
               fontWeight={600}
               display="flex"
               gap={3}
               alignItems="center"
            >
               {heading}
            </Card.Title>
            <Card.Text fontSize="lg" pr={0}>
               {text}
            </Card.Text>
            <Card.ButtonGroup>
               {showCheckEligibilityButton && (
                  <Card.Button onClick={openEligibilityModal}>
                     Check Eligibility
                  </Card.Button>
               )}
               {showSendEmailButton && (
                  <Card.ButtonLink href={`mailto:${INNERWELL_SUPPORT_EMAIL}`}>
                     Send email
                  </Card.ButtonLink>
               )}
               <Card.ButtonLink
                  href="/support"
                  variant={showContactSupportButton ? 'solid' : 'link'}
               >
                  Contact Member Support
               </Card.ButtonLink>
            </Card.ButtonGroup>
         </Card>

         <BoxedModal
            title="Insurance Eligibility"
            bg="background.secondary"
            isOpen={isEligibilityModalOpen}
            onClose={closeEligibilityModal}
         >
            <EligibilityFormIntake
               patient={patient}
               onCompleted={(isEligible) => {
                  closeEligibilityModal();

                  onCompletedEligibilityCheck?.(isEligible);
               }}
            />
         </BoxedModal>
      </Flex>
   );
};
