import { InsuranceError } from '@innerwell/dtos';
import { ReactNode } from 'react';
import { IconName } from '../Icon';

export const insuranceErrorToMessageMap: Record<
   InsuranceError,
   { heading: string; text: ReactNode; iconName?: IconName }
> = {
   [InsuranceError.MemberId]: {
      heading: 'Unrecognized Member ID',
      text: (
         <>
            {`Your insurance provider couldn't verify the member ID provided.
            Please check for typos or try using a different member ID. If
            everything looks correct, send a copy of your insurance card to
            care@helloinnerwell.com, and we'll reach out to them for
            verification.`}
         </>
      ),
   },
   [InsuranceError.MemberDob]: {
      heading: 'Unrecognized Date of Birth',
      text: (
         <>
            {`Your insurance provider couldn't verify the date of birth provided.
            Please double-check your information. If
            everything appears correct, send a copy of your insurance card to
            care@helloinnerwell.com, and we'll verify it with them.`}
         </>
      ),
   },
   [InsuranceError.MemberName]: {
      heading: 'Unrecognized Member Name',
      text: (
         <>
            {`Your insurance provider couldn't verify this name. Make sure you're
            using your full legal name as it appears on your insurance records.
            If the issue persists, send a copy of your insurance card to
            care@helloinnerwell.com, and we'll check with them.`}
         </>
      ),
   },
   [InsuranceError.Payer]: {
      heading: 'Payer System Unavailable',
      text: (
         <>
            {`Your insurance provider's system is temporarily down, so we couldn't
            verify your coverage. Please send a copy of your insurance card
            (front and back) to care@helloinnerwell.com, and we'll manually
            check once their system is back online.`}
         </>
      ),
   },
   [InsuranceError.PayerNpi]: {
      heading: 'Payer System Unavailable',
      text: (
         <>
            {`Your insurance provider's system is temporarily down, so we couldn't
            verify your coverage. Please send a copy of your insurance card
            (front and back) to care@helloinnerwell.com, and we'll manually
            check once their system is back online.`}
         </>
      ),
   },
   [InsuranceError.NetworkStatusPending]: {
      heading: 'Payer System Unavailable',
      text: (
         <>
            {`Your insurance provider's system is temporarily down, so we couldn't
            verify your coverage. Please send a copy of your insurance card
            (front and back) to care@helloinnerwell.com, and we'll manually
            check once their system is back online.`}
         </>
      ),
   },
   [InsuranceError.None]: {
      heading: 'Invalid Insurance Information',
      text: 'Please double check your information and try again.',
   },
   [InsuranceError.DiscrepancyPayer]: {
      heading: 'We are checking your insurance',
      text: `Thank you for providing your updated insurance information. We
            are currently reviewing it, and we’ll notify you once coverage
            verification is complete. For more information, please contact
            our support team.`,
      iconName: 'insurance-pending',
   },
   [InsuranceError.DiscrepancyClinician]: {
      heading: 'We are checking your insurance',
      text: `Thank you for providing your updated insurance information. We
            are currently reviewing it, and we’ll notify you once coverage
            verification is complete. For more information, please contact
            our support team.`,
      iconName: 'insurance-pending',
   },
};
