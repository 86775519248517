import { InsuranceInfoStatus, MyPatientResponseDto } from '@innerwell/dtos';
import { ReactNode } from 'react';
import { insuranceErrorToMessageMap } from './insuranceErrorToMessageMap';
import { Icon, IconName } from '../Icon';
import { NextLink } from '../NextLink/NextLink';
import { INNERWELL_SUPPORT_EMAIL } from '@/utils/consts';
import { Box } from '@chakra-ui/react';

const getIconColor = (iconName: IconName) => {
   if (iconName === 'insurance-ineligible') {
      return 'accent.danger';
   }

   if (iconName === 'insurance-error') {
      return 'accent.orange';
   }

   return 'accent.blue';
};

export const useInsuranceStatusText = (
   patient: MyPatientResponseDto,
): {
   heading: ReactNode;
   text: ReactNode;
   showCheckEligibilityButton: boolean;
   showSendEmailButton: boolean;
   showContactSupportButton: boolean;
} | null => {
   if (!patient.insurance) {
      return null;
   }

   const { status, error } = patient.insurance;

   if (status === InsuranceInfoStatus.NotStarted) {
      return {
         heading: <>Confirm your insurance coverage</>,
         text: (
            <>
               Please give us the details of your insurance coverage so that we
               can confirm your eligibility and verify that you are covered.
            </>
         ),
         showCheckEligibilityButton: true,
         showSendEmailButton: false,
         showContactSupportButton: false,
      };
   }

   if (status === InsuranceInfoStatus.Pending) {
      return {
         heading: (
            <>
               We are checking your insurance
               <Icon
                  name="insurance-pending"
                  boxSize={5}
                  color={getIconColor('insurance-pending')}
               />
            </>
         ),
         text: (
            <>
               {`Thank you for providing your updated insurance information. We
               are currently reviewing it, and we’ll notify you once coverage
               verification is complete. For more information, please contact
               our support team.`}
            </>
         ),
         showCheckEligibilityButton: false,
         showSendEmailButton: false,
         showContactSupportButton: true,
      };
   }

   if (status === InsuranceInfoStatus.PendingError) {
      return {
         heading: (
            <>
               Insurance Payer System Unavailable
               <Icon
                  name="insurance-error"
                  boxSize={5}
                  color={getIconColor('insurance-error')}
               />
            </>
         ),
         text: (
            <>
               {`Your insurance provider's system is temporarily down, so we
               couldn't verify your coverage. Please send a copy of your
               insurance card (front and back) to care@helloinnerwell.com, and
               we'll manually check once their system is back online.`}
            </>
         ),
         showCheckEligibilityButton: false,
         showSendEmailButton: true,
         showContactSupportButton: false,
      };
   }

   if (status === InsuranceInfoStatus.Ineligible) {
      return {
         heading: (
            <>
               Ineligible Plan{' '}
               <Icon
                  name="insurance-ineligible"
                  boxSize={5}
                  color={getIconColor('insurance-ineligible')}
               />
            </>
         ),
         text: (
            <>
               {`Your insurance provider says this plan isn't eligible. Please
               double-check your details and try again. If you believe this is a
               mistake, send a copy of your insurance card to `}
               <NextLink href={`${INNERWELL_SUPPORT_EMAIL}`}>
                  care@helloinnerwell.com
               </NextLink>
               , {`and we'll follow up with them.`}
               <br />
               <br />
               <Box
                  as="span"
                  fontSize="md"
                  opacity={0.8}
               >{`Note: We don't accept Medicaid or Medicare at this time.`}</Box>
            </>
         ),
         showCheckEligibilityButton: true,
         showSendEmailButton: false,
         showContactSupportButton: false,
      };
   }

   if (status === InsuranceInfoStatus.Error && error) {
      const iconName =
         insuranceErrorToMessageMap[error].iconName ?? 'insurance-error';

      return {
         heading: (
            <>
               {insuranceErrorToMessageMap[error].heading}
               <Icon
                  name={iconName}
                  boxSize={5}
                  color={getIconColor(iconName)}
               />
            </>
         ),
         text: insuranceErrorToMessageMap[error].text,
         showCheckEligibilityButton: true,
         showSendEmailButton: false,
         showContactSupportButton: false,
      };
   }

   return null;
};
