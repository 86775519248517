import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export default function usePatientParams({
   enabled,
}: {
   enabled?: boolean;
} = {}) {
   const { data: sessionData } = useSession();

   return useQuery({
      queryKey: queryKeys.params,
      queryFn: async () => {
         const response = await webApiClient.patients.getParams();
         return response.body;
      },
      enabled: (enabled ?? true) && !!sessionData,
   });
}
